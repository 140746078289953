import React, { useState } from 'react';
import './App.css';

function InputBox() {
  const [hasValue, setHasValue] = useState(false);
  const [inpVal, setInpVal] = useState('');
  console.log('s')

  const toggleMethod = () => {
    if (inpVal.length > 0) {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  };

  const updateInputVal = (e) => {
    setInpVal(e.target.value);
  };

  let message;
  let linkElement = null;

  const lowerCaseInput = inpVal.toLowerCase();

  if (lowerCaseInput === 'sofiia' || lowerCaseInput === 'sofika') {
    message = `Моя найкраща, ${inpVal}, я чекаю тебе за посиланням ♥`;
    linkElement = (
        <a href="https://zoom.us/j/98996904427?pwd=QTZiM2F1TWxNWEc0OE9wYy9OTXN1dz09" target="_blank" rel="noopener noreferrer">
          Клікни сюда для переходу
        </a>
    );
  } else if (hasValue) {
    message = `Дорога, ${inpVal}, вас на побачення запросить інший хлопець!`;
  } else {
    message = '';
  }

  return (
      <div className="input-wrapper">
        <div className={hasValue ? 'input-box shake' : 'input-box'}>
          <div className="add-icon" onClick={toggleMethod}>
            <div></div>
          </div>
          <input
              type="text"
              id="name"
              placeholder="enter your name"
              autoComplete="off"
              onChange={updateInputVal}
              className={hasValue ? 'go' : ''}
          />
          <div className={hasValue ? 'display-div comeup' : 'display-div'}>
            <p>{message}</p>
            {linkElement}
          </div>
        </div>
      </div>
  );
}

function App() {
  return (
      <div>
        <InputBox />
      </div>
  );
}

export default App;
